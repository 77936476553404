export default {
  name: '',
  data() {
    return this.initial_state();
  },
  methods: {
    /**
     *  Initialize data
     */
    initial_state() {
      return {};
    },
  },
};
