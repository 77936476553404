import { render, staticRenderFns } from "./footer.web.html?vue&type=template&id=3dae6df4&web=true&"
import script from "./footer.js?vue&type=script&lang=js&"
export * from "./footer.js?vue&type=script&lang=js&"
import style0 from "./footer.web.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports